import React, { useEffect } from 'react';
import { Box, Theme, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ErrorMantenimiento } from '@screens/welcomeToInter/ErrorMantenimiento';
import { useChangePrice } from '@hooks/useChangePrice';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#f5f5f5',
    paddingBottom: '40px',
  },
}));

export const Mantenimiento: React.FC = () => {
  const classes = useStyles();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useChangePrice();

  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            marginTop: 80,
            minHeight: '70vh',
            position: 'relative',
          }}
        >
          <ErrorMantenimiento />
        </Box>
      </Container>
    </div>
  );
};
